import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 175px;
  
  h1, 
  h2,
  h3 {
    margin-bottom: 24px 0;
    text-align: center;
  }
  h1 {
    text-align: center;
    line-height: 56px;
    font-size: 40px;
  }
  p {
    margin: 24px 0;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
  img {
   margin: 32px 0;
   max-width: 900px;
   @media (max-width: 1100px) {
     max-width: 100%;
   }
  }
`;
